HTMLElement.prototype.getBoundingClientRect = (function () {
	let oldGetBoundingClientRect = HTMLElement.prototype.getBoundingClientRect;
	return function() {
		try {
			return oldGetBoundingClientRect.apply(this, arguments);
		} catch (e) {
			return {
				left: '',
				right: '',
				top: '',
				bottom: ''
			};
		}
	};
})();